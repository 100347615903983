import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ISD_CODE,
  LOGIN_ENDPOINT,
  LOGIN_PRODUCT_ID,
  LOGIN_TENANT_ID,
  PLATFORM,
  REQUEST_PRODUCT_ID,
  VALID_SALES_ROLE_IDS,
  X_TENANT,
} from "constants/config";

const fetchPackages = async (filters) => {
  const url = new URL(`${process.env.REACT_APP_OMS_GET_PACKAGES_URL}`);
  const rolesString = localStorage.getItem("roles");
  const roles = rolesString
    ? rolesString.split(",").map((role) => role.trim())
    : [];

  const validPageUrls = roles
    .map((roleId) => VALID_SALES_ROLE_IDS[Number(roleId)])
    .find((pageUrl) => pageUrl !== undefined);

  const params = {
    exams: (filters.exams || []).join(","),
    grade: (filters.grade || []).join(","),
    producttype: PRODUCT_TYPES.map((type) => type.value).join(","),
    pageUrl: validPageUrls,
    verticalId : filters.verticalId
  };

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      url.searchParams.append(key, params[key]);
    }
  });

  const headers = {
    "x-tenant": X_TENANT,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { headers });

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();

  if (data.status && data.message === "success") {
    const formattedData = data.data
      .filter((pkg) => pkg.packageId !== null)
      .flatMap((pkg) =>
        pkg.packageSubscriptions.map((subscription) => ({
          id: subscription.packageSubscriptionId,
          packageId: pkg.packageId,
          displayName: pkg.displayName,
          planName: subscription.planName,
          planValidity: pkg.packageValidityDate,
          mrp: subscription.packagePaymentPlan.mrp,
          componentProductTypeIds: subscription.components.map(
            (component) => component.componentProduct.componentProductTypeId
          ),
        }))
      );
    return formattedData;
  } else {
    throw new Error("Unexpected API response format");
  }
};

export const useFetchPackages = (filters, enabled = true) => {
  return useQuery({
    queryKey: ["packages", filters],
    queryFn: () => fetchPackages(filters),
    enabled,
  });
};

const fetchPostalCodeDetails = async (postalCode) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-postal-code-details/${postalCode}`
  );

  const headers = {
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    accept: "application/json",
  };

  const response = await fetch(url, { headers });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(`Error: ${data.message}`);
  }

  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error("Unexpected API response format");
  }
};

export const useFetchPostalCodeDetails = (postalCode) => {
  const isQueryEnabled = postalCode && postalCode.length === 6;
  return useQuery({
    queryKey: ["postalCodeDetails", postalCode],
    queryFn: () => fetchPostalCodeDetails(postalCode),
    enabled: !!isQueryEnabled,
  });
};

const fetchUpdatedPrice = async (payload) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/fetch-price`);

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};

export const useFetchUpdatedPrice = (payload) => {
  return useQuery({
    queryKey: ["price", payload],
    queryFn: () => fetchUpdatedPrice(payload),
    enabled: false,
    retry: false,
  });
};

const punchOrder = async (payload) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/punch-order`);

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || `Error: ${response.statusText}`);
  }

  if (data.status === "success") {
    return data.data.order_id;
  }
  throw new Error(
    data.message || "An error occurred while processing the request."
  );
};
export const usePunchOrder = () => {
  return useMutation({ mutationFn: punchOrder });
};

const fetchCustomerOrderDetails = async (payload) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/fetch-customer-orders-details`
  );

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (data.status && data.status === "success") {
    return data.data.order_details;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};

export const useFetchCustomerOrderDetails = (payload) => {
  return useQuery({
    queryKey: ["customer_order_details", payload],
    queryFn: () => fetchCustomerOrderDetails(payload),
    enabled: !!payload,
  });
};

const fetchSelectedOrderDetails = async (order_id) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-order-info/${order_id}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { headers });

  const data = await response.json();

  if (data.status && data.status === "success") {
    return data.data.order_details;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};

export const useSelectedOrderDetails = (order_id) => {
  return useQuery({
    queryKey: ["selected_order_id", order_id],
    queryFn: () => fetchSelectedOrderDetails(order_id),
    enabled: !!order_id,
  });
};

const getToken = async () => {
  const login_response = await fetch(LOGIN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      platform: PLATFORM,
      "product-id": LOGIN_PRODUCT_ID,
      "tenant-id": LOGIN_TENANT_ID,
    },
    body: JSON.stringify({
      admission_number: "8085650681",
      isd_code: ISD_CODE,
      phone: "8085650681",
      password: "password",
      product_id: REQUEST_PRODUCT_ID,
      tenant_id: LOGIN_TENANT_ID,
    }),
  });

  if (!login_response.ok) {
    const errorData = await login_response.json();
    const errorMessage = errorData.detail || "Unable to log in";
    throw new Error(errorMessage);
  }

  const loginData = await login_response.json();
  return loginData.accessToken;
};

const fetchCustomerInfo = async (phone_number) => {
  try {
    const token = await getToken();

    const url = process.env.REACT_APP_GET_CUSTOMER_INFO_URL;

    const payload = {
      phone: phone_number,
      tenant_id: "1",
      product_id: "300",
    };

    const headers = {
      accept: "application/json",
      "product-id": "1000",
      "tenant-id": "1",
      platform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (response.status === 200) {
      return {
        user_id: data.user_id,
      };
    } else {
      throw new Error(
        data.detail || "An error occurred while processing the request."
      );
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useFetchCustomerInfo = (phone_number) => {
  return useQuery({
    queryKey: ["customer_info", phone_number],
    queryFn: () => fetchCustomerInfo(phone_number),
    enabled: !!phone_number,
  });
};

const discardOrder = async (orderId) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/discard-order/${orderId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: null,
  });

  if (!response.ok) {
    throw new Error(`Failed to discard order: ${response.statusText}`);
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while discarding the order."
    );
  }
};

export const useDiscardOrder = () => {
  return useMutation({ mutationFn: discardOrder });
};

const createPaymentLink = async (payload) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/create-payment-link`
  );
  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};
export const useCreatePaymentLink = () => {
  return useMutation({ mutationFn: createPaymentLink });
};

const requestCancelOrder = async (payload) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/request-cancellation`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to request cancellation of order: ${response.statusText}`
    );
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message ||
        "An error occurred while requesting cancellation of order."
    );
  }
};

export const useRequestCancelOrder = () => {
  return useMutation({ mutationFn: requestCancelOrder });
};
const punchPayment = async (payload) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/punch-payment`);
  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};
export const usePunchPayment = () => {
  return useMutation({ mutationFn: punchPayment });
};

const fetchPendingOrders = async (sales_person_id) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-pending-orders-details/${sales_person_id}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
    body: null,
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch pending order: ${response.statusText}`);
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data.order_details;
  } else {
    throw new Error(
      data.message || "An error occurred while fetching pending orders."
    );
  }
};

export const useFetchPendingOrders = (sales_person_id) => {
  return useQuery({
    queryKey: ["pendingOrders", sales_person_id],
    queryFn: () => fetchPendingOrders(sales_person_id),
  });
};

export const GRADES = [
  { key: 1, label: "Grade 1", value: "Grade 1" },
  { key: 2, label: "Grade 2", value: "Grade 2" },
  { key: 3, label: "Grade 3", value: "Grade 3" },
  { key: 4, label: "Grade 4", value: "Grade 4" },
  { key: 5, label: "Grade 5", value: "Grade 5" },
  { key: 6, label: "Grade 6", value: "Grade 6" },
  { key: 7, label: "Grade 7", value: "Grade 7" },
  { key: 8, label: "Grade 8", value: "Grade 8" },
  { key: 9, label: "Grade 9", value: "Grade 9" },
  { key: 10, label: "Grade 10", value: "Grade 10" },
  { key: 11, label: "Grade 11", value: "Grade 11" },
  { key: 12, label: "Grade 12", value: "Grade 12" },
  { key: 13, label: "Grade 13", value: "Grade 13" },
];

export const TARGET_EXAMS = [
  { key: 1, label: "NEET", value: "NEET" },
  { key: 2, label: "JEEMAIN", value: "JEEMAIN" },
  { key: 3, label: "JEEMAIN,JEEADVANCED", value: "JEEMAIN,JEEADVANCED" },
  { key: 4, label: "Doubts", value: "Doubts" },
  { key: 5, label: "APTITUDETEST", value: "APTITUDETEST" },
  { key: 6, label: "FOUNDATIONTEST", value: "FOUNDATIONTEST" },
  { key: 7, label: "CBSE,JEEMAIN", value: "CBSE,JEEMAIN" },
  { key: 8, label: "CBSE", value: "CBSE" },
  { key: 9, label: "CUET,CBSE", value: "CUET,CBSE" },
  {
    key: 10,
    label: "JEEMAIN,JEEADVANCED,CBSE,NEET",
    value: "JEEMAIN,JEEADVANCED,CBSE,NEET",
  },
  { key: 11, label: "RMO", value: "RMO" },
  { key: 12, label: "IJSO", value: "IJSO" },
  { key: 13, label: "KCET", value: "KCET" },
  { key: 14, label: "BITSAT", value: "BITSAT" },
  { key: 15, label: "COMEDK", value: "COMEDK" },
  { key: 16, label: "SRMJEEE", value: "SRMJEEE" },
  { key: 17, label: "VITEEE", value: "VITEEE" },
  { key: 18, label: "KIITEE", value: "KIITEE" },
  { key: 19, label: "MHCET", value: "MHCET" },
  { key: 20, label: "MET", value: "MET" },
  { key: 21, label: "AEEE", value: "AEEE" },
  { key: 22, label: "EAMCET", value: "EAMCET" },
  {
    key: 23,
    label:
      "JEEMAIN, KCET, BITSAT, COMEDK, SRMJEEE, VITEEE, KIITEE, MHCET, MET, AEEE, EAMCET",
    value:
      "JEEMAIN, KCET, BITSAT, COMEDK, SRMJEEE, VITEEE, KIITEE, MHCET, MET, AEEE, EAMCET",
  },
  {
    key: 24,
    label:
      "KCET, BITSAT, COMEDK, SRMJEEE, VITEEE, KIITEE, MHCET, MET, AEEE, EAMCET",
    value:
      "KCET, BITSAT, COMEDK, SRMJEEE, VITEEE, KIITEE, MHCET, MET, AEEE, EAMCET",
  },
  { key: 25, label: "WIZCLUB", value: "WIZCLUB" },
  { key: 26, label: "SUMMERCAMP", value: "SUMMERCAMP" },
  { key: 27, label: "HOTS", value: "HOTS" },
  { key: 28, label: "ICSE", value: "ICSE" },
  { key: 29, label: "EEP", value: "EEP" },
];

export const PRODUCT_TYPES = [
  { key: 1, label: "Assessment", value: "Assessment" },
  { key: 2, label: "liveclasses", value: "liveclasses" },
  { key: 3, label: "selflearn", value: "selflearn" },
  { key: 4, label: "Doubts", value: "Doubts" },
  { key: 5, label: "recordedCourse", value: "recordedCourse" },
  { key: 6, label: "Hardware", value: "Hardware" },
  { key: 7, label: "Support", value: "Support" },
];

export const useOrderCancelReasons = () => ({
  isPending: false,
  data: [
    {
      id: 1,
      value: "1",
      label: "Customer Centric",
    },
    {
      id: 2,
      value: "2",
      label: "Sales User Centric",
    },
    {
      id: 3,
      value: "3",
      label: "Finance Partner Centric",
    },
  ].map((type) => ({
    id: type.id,
    value: type.value,
    label: type.label,
  })),
});

const fetchLoanProviderDetails = async () => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-loan-provider-details`
  );

  const headers = {
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    accept: "application/json",
  };

  const response = await fetch(url, { headers });

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error("Unexpected API response format");
  }
};

export const useFetchLoanProviderDetails = () => {
  return useQuery({
    queryKey: ["loanProviders"],
    queryFn: fetchLoanProviderDetails,
    enabled: true,
  });
};

const addNote = async (payload) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/add-note`);

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`Failed to add note to the order: ${response.statusText}`);
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while adding note to the order."
    );
  }
};

export const useAddNote = () => {
  return useMutation({ mutationFn: addNote });
};

const fetchPaymentReceipt = async (paymentId) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-payment-receipt/${paymentId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message ||
        `Failed to fetch payment receipt. Status: ${response.status}`
    );
  }

  const data = await response.json();

  if (data.status === "success") {
    return { signedUrl: data.data.signed_url };
  } else {
    throw new Error(
      data.message || "An error occurred while fetching the payment receipt."
    );
  }
};

export const useFetchPaymentReceipt = (paymentId) => {
  const query = useQuery({
    queryKey: ["paymentReceipt", paymentId],
    queryFn: () => fetchPaymentReceipt(paymentId),
    enabled: !!paymentId,
  });
  return {
    ...query,
    fetchPaymentReceipt,
  };
};

const fetchOrderSummaryPDF = async (orderId) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/generate-or-get-order-summary/${orderId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  const data = await response.json();

  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};

export const useFetchOrderSummaryPDF = (orderId) => {
  return useQuery({
    queryKey: ["OrderSummary", orderId],
    queryFn: () => fetchOrderSummaryPDF(orderId),
    enabled: false,
    retry: false,
  });
};

const fetchTaxInvoicePDF = async (orderId) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-tax-invoice/${orderId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || `Failed to fetch tax invoice.`);
  }

  const data = await response.json();

  if (data.status === "success") {
    return { signedUrl: data.data.signed_url };
  } else {
    throw new Error(
      data.message || "An error occurred while fetching tax invoice."
    );
  }
};

export const useFetchTaxInvoicePDF = (orderId) => {
  const query = useQuery({
    queryKey: ["taxInvoice", orderId],
    queryFn: () => fetchTaxInvoicePDF(orderId),
    enabled: !!orderId,
  });
  return {
    ...query,
    fetchTaxInvoicePDF,
  };
};

const requestTransferOrder = async (payload) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/create-order-modification-request`
  );

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || `Error: ${response.statusText}`);
  }

  if (data.status === "success") {
    return data.data.order_proposal_id;
  }
  throw new Error(
    data.message || "An error occurred while processing the request."
  );
};
export const useRequestTransferOrder = () => {
  return useMutation({ mutationFn: requestTransferOrder });
};

const fetchAllPayments = async (order_id) => {
  const url = new URL(
    `${process.env.REACT_APP_ONE_OMS_URL}/get-all-payments/${order_id}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  const data = await response.json();

  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};

export const useFetchAllPayments = (order_id) => {
  return useQuery({
    queryKey: ["order_id", order_id],
    queryFn: () => fetchAllPayments(order_id),
    enabled: !!order_id,
  });
};

const editLoanId = async (payload) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/edit-loan-id`);
  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};
export const useEditLoanId = () => {
  return useMutation({ mutationFn: editLoanId });
};

const cancelLoan = async (loan_id) => {
  const url = new URL(`${process.env.REACT_APP_ONE_OMS_URL}/cancel-loan/${loan_id}`);
  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_ONE_OMS_SECRET_KEY}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
  });
  const data = await response.json();
  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};
export const useCancelLoan = () => {
  return useMutation({ mutationFn: cancelLoan });
};

const verticalAndTenantsId = async () => {
  const url = new URL(`${process.env.REACT_APP_PACKAGE_MANAGEMENT_URL}/get-all-vertical-and-tenants`);
  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_PACKAGE_MANAGEMENT_SECRET_KEY}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const data = await response.json();
  if (data.status && data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while processing the request."
    );
  }
};
export const useVerticalAndTenantsId = () => {
  return useQuery({
    queryFn: () => verticalAndTenantsId(),
  });};