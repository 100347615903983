import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./containers/Login/Login";
import routes from "./constants/routes";
import HomePage from "./containers/HomePage/HomePage";
import PunchOrder from "containers/PunchOrder/PunchOrder";
import PaymentPreference from "containers/PaymentPreference/PaymentPreference";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import CustomerOrder from "containers/CustomerOrder/CustomerOrder";
import PageNotFound from "components/PageNotFound";
import EditPayments from "containers/EditPayments/EditPayments";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to={routes.homePage.homePage} />} />
    <Route path={routes.login.root} element={<Login />} />
    <Route element={<AuthenticatedRoute />}>
      <Route path={routes.homePage.homePage} element={<HomePage />} />
      <Route
        path={routes.customerOrders.customerOrders}
        element={<CustomerOrder />}
      />
      <Route path={routes.punchOrder.punchOrder} element={<PunchOrder />} />
      <Route
        path={`${routes.paymentPreference.paymentPreference}`}
        element={<PaymentPreference />}
      />
      <Route
        path={`${routes.editPayments.editPayments}`}
        element={<EditPayments />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  </Routes>
);

export default AppRoutes;
