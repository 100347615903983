const routes = {
  homePage: {
    homePage: "/homePage",
  },
  login: {
    root: "/login",
  },
  punchOrder: {
    punchOrder: "/punchOrder",
  },
  paymentPreference: {
    paymentPreference: "/paymentPreference",
  },
  editPayments: {
    editPayments: "/editPayments",
  },
  customerOrders: {
    customerOrders: "/customerOrders",
  },
  pageNotFound: {
    pageNotFound: "/pageNotFound",
  },
};

export default routes;
