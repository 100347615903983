import React from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_COUNTRY_NAME, ORDER_STATUSES } from "constants/config";
import { ErrorMessage, PrimaryButton } from "widgets";
import CustomerOrder from "containers/CustomerOrder/CustomerOrder";

import styles from "./CustomerDetails.module.scss";
import { useSelectedOrderDetails } from "store/backend/assets";
import Loader from "Shared/Loader/Loader";

const handleOnClickPunchOrderButton = (navigate, uam_id, user_id) => {
  if (user_id) {
    navigate(`/punchOrder?uam_id=${user_id}`);
    return;
  }
  navigate(`/punchOrder?uam_id=${uam_id}`);
  return;
};

const CanPunchOrder = (orderDetails) => {
  if (!!orderDetails && orderDetails.length === 0) {
    return true;
  }
  const statusIds = orderDetails.map((order) => order.status.id);
  if (
    statusIds.some((statusId) =>
      [
        ORDER_STATUSES.ACCEPTED,
        ORDER_STATUSES.CANCELLATION_REQUESTED,
        ORDER_STATUSES.SUSPENDED,
      ].includes(statusId)
    )
  ) {
    return false;
  }
  return true;
};

const CustomerDetails = (props) => {
  const {
    selectedOrderID,
    uam_id,
    user_id,
    errorMessageOnCustomerDetails,
    orderDetails,
    isOrderDetailsLoading,
    isErrorOnCustomerDetails,
  } = props;
  const navigate = useNavigate();

  const {
    isPending: isSelectedOrderDetailsLoading,
    data: selectedOrderDetails,
    isError: isErrorOnSelectedOrderDetails,
    error: errorMessageOnSelectedOrderDetails,
  } = useSelectedOrderDetails(selectedOrderID);

  if (isOrderDetailsLoading || isSelectedOrderDetailsLoading) {
    return <Loader />;
  }

  if (isErrorOnSelectedOrderDetails) {
    return (
      <ErrorMessage>
        {errorMessageOnSelectedOrderDetails.message || "Something went wrong"}{" "}
      </ErrorMessage>
    );
  }

  const customerId = uam_id ?? user_id; 

  return (
    <div className={styles.customerDetails}>
      {selectedOrderDetails ? (
        <>
          <div>
            <span className={styles.title}>Billing Name:</span>
            <span className={styles.value}>
              {
                selectedOrderDetails.address_details.billing_address
                  .recipient_name
              }
            </span>
          </div>
          <div>
            <span className={styles.title}>Customer Id:</span>
            <span className={styles.value}>{customerId}</span>
          </div>
          <div>
            <span className={styles.title}>Country:</span>
            <span className={styles.value}>{DEFAULT_COUNTRY_NAME}</span>
          </div>
          <div>
            <span className={styles.title}>Billing Phone No:</span>
            <span className={styles.value}>
              {
                selectedOrderDetails.address_details.billing_address
                  .masked_phone_no
              }
            </span>
          </div>
          <div>
            <span className={styles.title}>State:</span>
            <span className={styles.value}>
              {selectedOrderDetails.address_details.billing_address.state}
            </span>
          </div>
          <div>
            <span className={styles.title}>City:</span>
            <span className={styles.value}>
              {selectedOrderDetails.address_details.billing_address.city}
            </span>
          </div>
          <div className={styles.punchOrderButton}>
            {CanPunchOrder(orderDetails) &&
              selectedOrderDetails.payment_details.length !== 0 && (
                <PrimaryButton
                  onClick={() =>
                    handleOnClickPunchOrderButton(navigate, uam_id, user_id)
                  }
                >
                  Punch Order
                </PrimaryButton>
              )}
          </div>
        </>
      ) : isOrderDetailsLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No orders found.</p>
      )}
      {isErrorOnCustomerDetails && (
        <ErrorMessage>
          {errorMessageOnCustomerDetails?.message ||
            "An error occurred while loading customer details"}
        </ErrorMessage>
      )}
    </div>
  );
};
export default CustomerDetails;
