import React, { useState } from "react";

import {
  CheckBox,
  PrimaryButton,
  SecondaryButton,
  MultipleSelect,
  SearchField,
  ErrorMessage,
  SingleSelect,
} from "widgets";
import { useFetchPackages, GRADES, TARGET_EXAMS, useVerticalAndTenantsId } from "store/backend/assets";
import Loader from "Shared/Loader/Loader";
import SelectedPackageSubscriptions from "components/OrderSummary/SelectedPackageSubscriptions";

import styles from "./PackageSubscription.module.scss";
import { INR_SYMBOL } from "constants/config";

const handlePackageSelection = (
  packageSubscription,
  selectedPackageSubscriptions,
  onPackagesSelected
) => {
  if (
    selectedPackageSubscriptions.some((p) => p.id === packageSubscription.id)
  ) {
    const updatedSelections = selectedPackageSubscriptions.filter(
      (p) => p.id !== packageSubscription.id
    );
    onPackagesSelected(updatedSelections);
  } else {
    const updatedSelections = [
      ...selectedPackageSubscriptions,
      packageSubscription,
    ];
    onPackagesSelected(updatedSelections);
  }
};

const handleOnClickRefreshButton = (onFilterChange) => {
  onFilterChange("selectedGrade", []);
  onFilterChange("selectedTargetExam", []);
};

const handleOnClickSearchButton = (refetch) => {
  refetch();
};

const isSearchButtonEnabled = (selectedTargetExam, selectedGrade) =>
  selectedTargetExam.length > 0 && selectedGrade.length > 0;

const handleVerticalChange = (event, verticalAndTenantIds, setSelectedVertical, setSelectedTenant, onPackagesSelected,setTenantMenuItems) => {
  const newVerticalId = event.target.value;
  setSelectedVertical(newVerticalId);
  setSelectedTenant(null);
  onPackagesSelected([]);

  const selectedVerticalTenants = verticalAndTenantIds.find(
    (vertical) => vertical.id === newVerticalId
  )?.tenants || [];

  setTenantMenuItems(
    selectedVerticalTenants.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }))
  );
};
const handleTenantChange = (event, setSelectedTenant) => {
  setSelectedTenant(event.target.value);
};

const renderPackageDetails = (
  packageSubscription,
  selectedPackageSubscriptions,
  onPackagesSelected
) => {
  return (
    <div key={packageSubscription.id} className={styles.packageDetailBox}>
      <CheckBox
        className={styles.packageCheckBox}
        checked={selectedPackageSubscriptions.some(
          (p) => p.id === packageSubscription.id
        )}
        handleCheck={() =>
          handlePackageSelection(
            packageSubscription,
            selectedPackageSubscriptions,
            onPackagesSelected
          )
        }
      />
      <div className={styles.packageDetailContent}>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Package Name:</span>
          <span>{packageSubscription.displayName}</span>
        </div>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Plan Name:</span>
          <span>{packageSubscription.planName}</span>
        </div>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Validity:</span>
          <span>{packageSubscription.planValidity}</span>
        </div>
        <div className={styles.mrp}>
          <span className={styles.detailTitle}>MRP:</span>
          <span>{INR_SYMBOL + packageSubscription.mrp}</span>
        </div>
      </div>
    </div>
  );
};

const PackageSubscription = ({
  selectedPackageSubscriptions,
  onPackagesSelected,
  filters,
  searchTerm,
  onFilterChange,
  onSearchTermChange,
  handleChangePackageSubscriptionSelection,
}) => {
  const { selectedGrade, selectedTargetExam } = filters;
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantMenuItems, setTenantMenuItems] = useState([]);
  localStorage.setItem("verticalID", selectedVertical);
  const appliedFilters = {
    exams: selectedTargetExam,
    grade: selectedGrade,
    verticalId: selectedVertical,
    tenantId: selectedTenant
  };

  const {
    isFetching,
    isPending: isPackageDetailsLoading,
    data: packageDetails,
    error: packageDetailsError,
    refetch,
  } = useFetchPackages(appliedFilters, false);

  const { data: verticalAndTenantIds, isPending: isVerticalAndTenantIdsLoading } =
    useVerticalAndTenantsId();

  if (isVerticalAndTenantIdsLoading) {
    return <Loader />
  }

  const filteredPackages = !!packageDetails
    ? packageDetails.filter((pkg) =>
      pkg.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  const verticalMenuItems = verticalAndTenantIds.map(({ id, name }) => ({
    key: id,
    value: id,
    label: name,
  }));


  return (
    <div className={styles.mainContainer}>
      <div className={styles.packageContainer}>

        <div className={styles.verticalAndTenantIdContainer}>
          <SingleSelect
            name="vertical"
            handleChange={(event) =>
              handleVerticalChange(event, verticalAndTenantIds, setSelectedVertical, setSelectedTenant, onPackagesSelected,setTenantMenuItems)
            }
            menuItems={verticalMenuItems}
            tag="Select a Vertical "
          />
          <SingleSelect
            name="tenant"
            value={selectedTenant}
            handleChange={(event) => handleTenantChange(event, setSelectedTenant)}
            menuItems={tenantMenuItems}
            tag="Select a Tenant"
            disabled={!selectedVertical}
          />
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.gradesDropdown}>
            <MultipleSelect
              multiple
              name="grade"
              tag="Select Grade"
              value={selectedGrade}
              menuItems={GRADES}
              handleChange={(event) =>
                onFilterChange("selectedGrade", event.target.value)
              }
              showCheckbox={true}
            />
          </div>
          <div className={styles.targetExamDropdown}>
            <MultipleSelect
              multiple
              name="targetExam"
              tag="Select Target Exam"
              value={selectedTargetExam}
              menuItems={TARGET_EXAMS}
              handleChange={(event) =>
                onFilterChange("selectedTargetExam", event.target.value)
              }
              showCheckbox={true}
            />
          </div>
          <div className={styles.packageDetailsButtonContainer}>
            <PrimaryButton
              disabled={
                !isSearchButtonEnabled(selectedTargetExam, selectedGrade)
              }
              onClick={() => handleOnClickSearchButton(refetch)}
            >
              Search
            </PrimaryButton>
            <SecondaryButton
              onClick={() => handleOnClickRefreshButton(onFilterChange)}
            >
              Clear
            </SecondaryButton>
          </div>
        </div>
        <div className={styles.searchFieldContainer}>
          <SearchField
            placeholder="Search by package name"
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e.target.value)}
          />
        </div>
        <div className={styles.packageDetailsContainer}>
          {isPackageDetailsLoading && isFetching ? (
            <Loader />
          ) : (
            <>
              {packageDetailsError && (
                <ErrorMessage>
                  Error: {packageDetailsError.message}
                </ErrorMessage>
              )}
              {filteredPackages.length > 0 ? (
                filteredPackages.map((packageSubscription) =>
                  renderPackageDetails(
                    packageSubscription,
                    selectedPackageSubscriptions,
                    onPackagesSelected
                  )
                )
              ) : packageDetails && packageDetails.length === 0 ? (
                <ErrorMessage>No Packages found</ErrorMessage>
              ) : (
                <p>Select filters to search Package Subscriptions.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.selectedPackageSubscriptionsContainer}>
        <SelectedPackageSubscriptions
          selectedPackageSubscriptions={selectedPackageSubscriptions}
          handleChangePackageSubscriptionSelection={
            handleChangePackageSubscriptionSelection
          }
        />
      </div>
    </div>
  );
};

export default PackageSubscription;
