import { useSearchParams } from "react-router-dom";
import Loader from "Shared/Loader/Loader";
import {
  useCancelLoan,
  useEditLoanId,
  useFetchAllPayments,
} from "store/backend/assets";
import {
  ErrorMessage,
  PrimaryButton,
  Table,
  Dialog,
  Textfield,
  ErrorButton,
} from "widgets";

import styles from "./EditPayments.module.scss";
import { LOAN_STATUSES } from "constants/config";
import { useState } from "react";
import { useSnackbarContext } from "widgets/SnackBar/SnackBar";

const handleDialogClose = (setOpenDialogContent) => () => {
  setOpenDialogContent(null);
};

const getColumns = () => {
  return [
    { label: "Amount", field: "amount" },
    { label: "Status", field: "status" },
    { label: "Method", field: "method" },
    { label: "Action", field: "action" },
  ];
};

const handleOnEditPayment = (
  setOpenEditLoanDialogContent,
  setLoanApplicationNumber,
  loan,
  setLoanPaymentId
) => {
  setLoanApplicationNumber(loan.loan_id);
  setOpenEditLoanDialogContent(true);
  setLoanPaymentId(loan.id);
};

const handleOnDeletePayment = (
  setOpenConfirmLoanCancelDialogContent,
  loan_id
) => {
  setOpenConfirmLoanCancelDialogContent(loan_id);
};

const isEditable = (loan) => {
  return loan.status.id === LOAN_STATUSES.CREATED;
};

const isCancellable = (loan) => {
  return loan.status.id === LOAN_STATUSES.CREATED;
};

const getRows = (
  payments,
  setOpenEditLoanDialogContent,
  setLoanApplicationNumber,
  setOpenConfirmLoanCancelDialogContent,
  setLoanPaymentId
) => {
  return payments.map((payment) => ({
    amount: payment.inr_conversion_amount,
    method: payment.method.name,
    status: payment.status.name,
    action:
      payment.loan_payment && payment.loan_payment.status.id === 1 ? (
        <div className={styles.actionButtons}>
          <PrimaryButton
            disabled={!isEditable(payment.loan_payment)}
            onClick={() =>
              handleOnEditPayment(
                setOpenEditLoanDialogContent,
                setLoanApplicationNumber,
                payment.loan_payment,
                setLoanPaymentId
              )
            }
          >
            Edit
          </PrimaryButton>
          <ErrorButton
            disabled={!isCancellable(payment.loan_payment)}
            onClick={() =>
              handleOnDeletePayment(
                setOpenConfirmLoanCancelDialogContent,
                payment.loan_payment.loan_id
              )
            }
          >
            Cancel Loan
          </ErrorButton>
        </div>
      ) : (
        <div className={styles.actionButtons}>N/A</div>
      ),
  }));
};

const handleEditLoanId = (
  loanApplicationNumber,
  loanPaymentId,
  editLoanId,
  openSnackbar,
  setOpenEditLoanDialogContent
) => {
  const payload = {
    loan_detail_id: loanPaymentId,
    new_loan_id: loanApplicationNumber,
  };
  editLoanId(payload, {
    onSuccess: (response) => {
      setOpenEditLoanDialogContent(null);
      openSnackbar("Loan Id Successfully updated.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError: (error) => {
      openSnackbar(`${error}`, "error");
    },
  });
};

const handleCancelLoan = (
  cancelLoan,
  loan_id,
  openSnackbar,
  setOpenConfirmLoanCancelDialogContent
) => {
  cancelLoan(loan_id, {
    onSuccess: (response) => {
      setOpenConfirmLoanCancelDialogContent(null);
      openSnackbar("Loan Successfully cancelled.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError: (error) => {
      openSnackbar(`${error}`, "error");
    },
  });
};

const EditPayments = () => {
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("order_id");
  const [openEditLoanDialogContent, setOpenEditLoanDialogContent] =
    useState(null);
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [loanPaymentId, setLoanPaymentId] = useState("");
  const [
    openConfirmLoanCancelDialogContent,
    setOpenConfirmLoanCancelDialogContent,
  ] = useState(null);
  const { openSnackbar } = useSnackbarContext();

  const {
    isPending: isFetchAllPaymentsLoading,
    data: payments,
    isError: isErrorOnPayments,
    error: errorMessageOnPayments,
  } = useFetchAllPayments(order_id);

  const { mutate: editLoanId, isPending: isEditLoanIdLoading } =
    useEditLoanId();

  const { mutate: cancelLoan, isPending: isCancelLoanLoading } =
    useCancelLoan();

  if (isFetchAllPaymentsLoading) {
    return <Loader />;
  }

  if (isErrorOnPayments) {
    return <ErrorMessage>{errorMessageOnPayments.message}</ErrorMessage>;
  }

  return (
    <>
      <div className={styles.header}>EDIT PAYMENTS</div>
      <div className={styles.paymentTable}>
        {payments.length > 0 ? (
          <Table
            columns={getColumns()}
            rows={getRows(
              payments,
              setOpenEditLoanDialogContent,
              setLoanApplicationNumber,
              setOpenConfirmLoanCancelDialogContent,
              setLoanPaymentId
            )}
          />
        ) : (
          <ErrorMessage>No payments done.</ErrorMessage>
        )}
      </div>
      <Dialog
        open={openEditLoanDialogContent !== null}
        handleClose={handleDialogClose(setOpenEditLoanDialogContent)}
      >
        <div className={styles.editLoanIdContainer}>
          <Textfield
            value={loanApplicationNumber}
            handleOnChange={(event) =>
              setLoanApplicationNumber(event.target.value)
            }
            height="auto"
          />
          <div className={styles.actionButtons}>
            <PrimaryButton
              disabled={isEditLoanIdLoading}
              onClick={() =>
                handleEditLoanId(
                  loanApplicationNumber,
                  loanPaymentId,
                  editLoanId,
                  openSnackbar,
                  setOpenEditLoanDialogContent
                )
              }
            >
              {isEditLoanIdLoading ? "UPDATING" : "UPDATE"}
            </PrimaryButton>
            <PrimaryButton
              onClick={handleDialogClose(setOpenEditLoanDialogContent)}
            >
              GO BACK
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openConfirmLoanCancelDialogContent !== null}
        handleClose={() =>
          handleDialogClose(setOpenConfirmLoanCancelDialogContent)
        }
      >
        <div className={styles.cancelLoanContainer}>
          <div className={styles.cancelLoanWarning}>
            Are you sure you want to cancel loan with Id:{" "}
            {openConfirmLoanCancelDialogContent} ?
          </div>
          <div className={styles.actionButtons}>
            <PrimaryButton
              onClick={handleDialogClose(setOpenConfirmLoanCancelDialogContent)}
            >
              GO BACK
            </PrimaryButton>
            <ErrorButton
              disabled={isCancelLoanLoading}
              onClick={() =>
                handleCancelLoan(
                  cancelLoan,
                  openConfirmLoanCancelDialogContent,
                  openSnackbar,
                  setOpenConfirmLoanCancelDialogContent
                )
              }
            >
              {isCancelLoanLoading ? "CANCELLING" : "CANCEL LOAN"}
            </ErrorButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EditPayments;
