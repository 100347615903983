import React from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "../Select/Select";
import MenuItem from "../MenuItem/CustomMenuItem";

const findLabelByValue = (selectedValue, menuItems) => {
  const selectedMenuItem = menuItems.find(
    (menuItem) => menuItem.value === selectedValue,
  );
  return selectedMenuItem ? selectedMenuItem.label : null;
};

const SingleSelect = (props) => {
  const {
    id,
    name,
    tag,
    value,
    label,
    handleChange,
    menuItems,
    errorMessage,
    disabled,
  } = props;
  const selectProps = {
    id,
    name,
    value,
    label,
  };

  return (
    <FormControl sx={{ width: "100%" }}>
       <InputLabel
          sx={{ backgroundColor: "white", fontWeight: "400" }}
          id={id}
        >
          {tag}
        </InputLabel>
      <Select
        {...selectProps}
        multiline
        displayEmpty
        renderValue={(selected) => {
          const selectedLabel = findLabelByValue(selected, menuItems);
          if (!selected) {
            return <em>{selectedLabel}</em>;
          }
          return selectedLabel
        }}
        onChange={handleChange}
        disabled={disabled}
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.key} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </FormControl>
  );
};

export default SingleSelect;
