import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import styles from "./CourseEnroll.module.scss";
import { Text } from "widgets";

const CourseEnroll = ({ uam_id }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const smartBatchingLink = `${process.env.REACT_APP_LEARN_URL}?uam_id=${uam_id}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(smartBatchingLink);
    setIsLinkCopied("Copied!");
  };

  return (
    <div className={styles.panelContainer}>
      <div className={styles.header}>Prioritize Enrollments</div>
      <div className={styles.content}>
        <p className={styles.paragraph}>
          Early confirmations get priority. Copy and share the link with the
          customer for faster service.
        </p>
        <div className={styles.importantSection}>
          <strong className={styles.importantTitle}>Important:</strong>
          <ul className={styles.list}>
            <li>Use registered (login) phone for authentication</li>
            <li>Share only if the order contains packages with live classes</li>
          </ul>
        </div>
        <div className={styles.linkSection}>
          <div className={styles.linkBox}>
            <span>
              {smartBatchingLink}
              {isLinkCopied && <div>Copied!</div>}
            </span>
            <button
              onClick={handleCopyToClipboard}
              className={styles.copyButton}
            >
              <FaRegCopy className={styles.copyIcon} />
            </button>
          </div>
          <div className={styles.footerNote}>
            Ensure customer confirms their batches to prioritize enrollment.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseEnroll;
